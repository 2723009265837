import {StateFactory} from "@way/app/devis/mon-tarif/store/mon-tarif.state";
import {createFeature, createReducer, createSelector, on} from "@ngrx/store";
import {devisActions} from "@way/app/devis/store/devis.actions";
import {motifSejourActions} from "@way/app/devis/etape-motif-sejour/store/etape-motif-sejour.actions";
import {ceamActions} from "@way/app/devis/etape-ceam/store/etape-ceam.actions";
import {confirmationAgeActions} from "@way/app/devis/etape-confirmation-age/store/etape-confirmation-age.actions";
import {periodeCouvertureActions} from "@way/app/devis/etape-periode-couverture/store/etape-periode-couverture.actions";
import {destinationActions} from "@way/app/devis/etape-destination/store/etape-destination.actions";
import {monTarifActions} from "@way/app/devis/mon-tarif/store/mon-tarif.actions";
import {getLibelleCorrespondantACodeMotif} from "@way/app/devis/getLibelleCorrespondantACodeMotif";

const initialState = StateFactory.createInitialDevisState();

export const devisMonTarifFeature = createFeature({
  name: 'devisMonTarif',
  reducer: createReducer(initialState,
    on(devisActions.utilisateurANavigueVersDevis,
      motifSejourActions.utilisateurASelectionneMotifSejour,
      ceamActions.utilisateurACliqueSurOui,
      ceamActions.utilisateurACliqueSurNon,
      confirmationAgeActions.utilisateurASelectionneConfirmationAge, periodeCouvertureActions.utilisateurAModifieFormulaire, periodeCouvertureActions.utilisateurACliqueSurBoutonDureeMoins, periodeCouvertureActions.utilisateurACliqueSurBoutonDureePlus, destinationActions.utilisateurASelectionnePaysDomicile, destinationActions.utilisateurASelectionnePaysDestination, (state) => ({
        ...state,
        monTarif: {
          ...initialState.monTarif,
        },
      })),
    on(monTarifActions.apiARenvoyeDevis, (state, action) => {
      return {
        ...state,
        monTarif: {
          devis: action.devis
        },
      }
    }),
  ),
  extraSelectors: ({selectMonTarif}) => {
    return {
      selectDevisId: createSelector(
        selectMonTarif, (monTarif)=>{
          return monTarif?.devis?.id;
        }
      ),
      selectLibelleSoustitre: createSelector(
        selectMonTarif,
        (monTarif) => {
          if (monTarif?.devis == null)
            return null;
          return getLibelleCorrespondantACodeMotif(monTarif!.devis!.parametrage.codeMotifSejour);
        }
      )
    }
  }
});
